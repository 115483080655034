import { A, redirect, useLocation, useNavigate } from "@solidjs/router";
import {
  createEffect,
  createMemo,
  createSignal,
  For,
  onCleanup,
  onMount,
  Show,
} from "solid-js";
import {
  activeLinkGradient,
  emailIconBlack,
  officeIcon,
  phoneIcon,
  squidLogo,
  userCircle,
  userProfileCircle,
} from "~/assets/assets";
import { PhosphorIcon } from "~/widgets/icons";
import { useModal } from "../modal";
import { openIntercom } from "~/utils/third_party/intercom";
import { toRupees } from "~/utils/number";
import { useSquidContext } from "~/context/squid_context";
import { LogoutModal } from "./logout_modal";
import { useOutsideClick } from "~/utils/hooks/use_outside_click";

export const Sidebar = (props: { onClose?: () => void }) => {
  const location = useLocation();

  const { openModal } = useModal()!;
  const { squidDataStore, updateDefaultAddress } = useSquidContext();
  const navigator = useNavigate();

  const [toggleProfilePg, setToggleProfilePg] = createSignal(false);
  const [isProfileHovered, setIsProfileHovered] = createSignal(false);

  let profileMenuRef: HTMLDivElement | undefined;
  let profileButtonRef: HTMLDivElement | undefined;

  useOutsideClick(
    () => profileMenuRef,
    () => {
      if (toggleProfilePg()) {
        setToggleProfilePg(false);
      }
    },
    () => profileButtonRef
  );

  const navItems = [
    { path: "/catalogue", label: "Catalogue", icon: "shopping-bag-open" },
    { path: "/proforma-invoice", label: "Proforma Invoices", icon: "notepad" },
    { path: "/purchases", label: "My purchases", icon: "receipt" },
  ];

  onMount(() => {
    onCleanup(() => {});
  });

  const defaultAddressId = createMemo(() => {
    return squidDataStore.businessDetails.details.defaultAddressId;
  });

  const handleSelection = async (addressId: string) => {
    try {
      await updateDefaultAddress(addressId);
    } catch (error) {
      console.log("Couldn't update address");
    }
  };

  return (
    <aside class="flex h-full w-[268px] flex-shrink-0 flex-col justify-between bg-[#0F0E12] py-4 text-white">
      <div>
        <div class="flex items-start justify-between">
          <div
            class="flex cursor-pointer items-center px-4 pb-4"
            onClick={() => {
              if (location.pathname == navItems[0].path) {
                return;
              }
              return navigator(navItems[0].path);
            }}
          >
            <img src={squidLogo} alt="hubble" class="mr-1 h-7 w-7" />
            <div class="flex flex-col">
              <div class="text-f12Bold text-white">SQUID</div>
              <div class="text-[8px] font-semibold text-white">By Hubble</div>
            </div>
          </div>
          <div
            class="flex cursor-pointer p-3 pt-0 sm:hidden"
            onClick={() => {
              props.onClose?.();
            }}
          >
            <PhosphorIcon name="x" fontSize={24} />
          </div>
        </div>
        <div class="flex justify-between border-y border-basePrimaryDark px-4 py-2">
          <p class="text-medium text-baseSecondaryMedium">Balance</p>
          <p class="text-bold">{toRupees(squidDataStore.walletBalance)}</p>
        </div>
        <nav class="py-4 text-subtitleSmall">
          <ul>
            <For each={navItems}>
              {(item) => (
                <A
                  href={item.path}
                  activeClass="bg-basePrimary text-white"
                  inactiveClass="pl-7 text-baseSecondaryMedium"
                  class="relative flex items-center gap-2 px-4 py-2.5  hover:text-white"
                >
                  <Show when={location.pathname === item.path}>
                    <hr class="h-5 w-0.5 rounded-sm border-2 border-white" />
                    <img src={activeLinkGradient} class="absolute" alt="" />
                  </Show>
                  <PhosphorIcon name={item.icon} fontSize={20} />
                  {item.label}
                </A>
              )}
            </For>
          </ul>
        </nav>
      </div>
      <div class=" text-medium text-baseSecondaryMedium">
        <p
          class="flex cursor-pointer items-center gap-2 px-4 py-2.5 pl-[25px]"
          onClick={() => {
            openIntercom();
          }}
        >
          <PhosphorIcon name="chat-dots" />
          Help & Support
        </p>
        <div
          ref={profileButtonRef}
          onClick={() => setToggleProfilePg(!toggleProfilePg())}
          onMouseEnter={() => setIsProfileHovered(true)}
          onMouseLeave={() => setIsProfileHovered(false)}
          class=" relative hidden cursor-pointer items-center gap-2 px-4 py-2.5 pl-[25px] hover:text-white sm:flex"
        >
          <img
            src={isProfileHovered() ? userCircle : userProfileCircle}
            alt=""
          />

          {squidDataStore.userProfile.name}

          <Show when={toggleProfilePg()}>
            <div
              ref={profileMenuRef}
              class="absolute bottom-2.5 left-[3.2rem] z-10 min-h-[266px] w-[352px] cursor-default rounded-2xl border border-baseTertiaryDark bg-whiteBg"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <div class=" p-5">
                <div class="mb-4 flex justify-between gap-4">
                  <p class="text-h5 text-textDark">
                    {squidDataStore.userProfile.name}
                  </p>
                  <button
                    onClick={() => {
                      return openModal(() => <LogoutModal />, "md:w-96");
                    }}
                    class="cursor-pointer text-f12 font-medium text-errorDark underline"
                  >
                    Logout
                  </button>
                </div>
                <div class="flex flex-col gap-2">
                  <Show when={squidDataStore.userProfile.phone}>
                    <div class="flex items-center justify-start gap-2">
                      <img src={phoneIcon} alt="" />
                      <p class="text-medium text-textDark">
                        {squidDataStore.userProfile.phone}
                      </p>
                    </div>
                  </Show>
                  <Show when={squidDataStore.userProfile.email}>
                    <div class="flex items-center justify-start gap-2">
                      <img src={emailIconBlack} alt="" />
                      <p class="text-medium text-textDark">
                        {squidDataStore.userProfile.email}
                      </p>
                    </div>
                  </Show>
                  <Show when={squidDataStore.businessDetails.businessName}>
                    <div class="flex items-center justify-start gap-2">
                      <img src={officeIcon} alt="" />
                      <p class="text-medium text-textDark">
                        {squidDataStore.businessDetails.businessName}
                      </p>
                    </div>
                  </Show>
                </div>
              </div>
              <hr class="border-baseTesrtiaryDark w-full border border-b" />
              <div class="flex flex-col p-5">
                <p class="mb-3 text-mediumBold text-textNormal">
                  Billing address
                </p>
                <For each={squidDataStore.businessDetails.details.addresses}>
                  {(address) => {
                    const addressId = address.id;
                    return (
                      <label class="mb-2.5 flex items-center justify-start gap-2.5 rounded-[10px] border border-baseTertiaryDark p-2.5 text-medium text-textDark">
                        <p>
                          {address.address1} {address.address2} {address.place}{" "}
                          {address.state} {address.pincode}
                        </p>
                        <input
                          type="radio"
                          class="h-5 w-5 scale-[1.2] rounded border border-baseTertiaryDark text-textDark accent-black"
                          checked={defaultAddressId() === addressId}
                          onChange={() => {
                            if (defaultAddressId() !== addressId) {
                              handleSelection(addressId);
                            }
                          }}
                          style={{ visibility: "hidden" }}
                        />
                        <div
                          class="inline-block h-5 w-5 cursor-pointer rounded-[4px] bg-none bg-center bg-no-repeat p-2"
                          style={{
                            background:
                              defaultAddressId() === addressId
                                ? "url(https://assets.myhubble.money/web-assets/white_tick.svg) no-repeat center"
                                : "white",
                            "background-repeat": "no-repeat",
                            "background-position": "center center",
                            border:
                              defaultAddressId() === addressId
                                ? "none"
                                : "1px solid #ddd",
                            "background-size": "18px 18px",
                            "background-color":
                              defaultAddressId() === addressId
                                ? "black"
                                : "transparent",
                          }}
                        />
                      </label>
                    );
                  }}
                </For>
              </div>
            </div>
          </Show>
        </div>
        <div class="flex justify-between px-4 py-2.5 pl-[25px] sm:hidden">
          <div
            class="flex cursor-pointer items-center gap-2"
            onClick={() => {
              return openModal(() => <LogoutModal />, "md:w-96");
            }}
          >
            <PhosphorIcon name="sign-out" />
            <button class="text-f12 font-medium" onClick={() => {}}>
              Logout
            </button>
          </div>
        </div>
      </div>
    </aside>
  );
};
