import { ButtonRegular } from "~/widgets/button";
import { PhosphorIcon } from "~/widgets/icons";
import { useModal } from "../modal";
import { clearCookie } from "~/utils/client_cookie";
import { Cookie } from "~/types";
import { clientRepo } from "~/server/apis/client_repo";

import { createSignal } from "solid-js";
import { useNavigate } from "@solidjs/router";

export const LogoutModal = () => {
  const { closeModal } = useModal()!;
  const navigator = useNavigate();
  const [showLogoutLoader, setShowLogoutLoader] = createSignal(false);

  const logoutHandler = async () => {
    setShowLogoutLoader(true);
    await clientRepo.logout();

    localStorage.removeItem("sessionId");
    setShowLogoutLoader(false);
    clearCookie(Cookie.SessionId);
    clearCookie(Cookie.Mode);
    clearCookie(Cookie.ClientId);
    clearCookie(Cookie.ClientSecret);

    closeModal();
    return navigator("/login", { replace: true });
  };

  return (
    <div class="flex flex-col justify-center">
      <div class="mb-6  mt-8 flex flex-col items-center justify-center">
        <div class="mb-4 flex h-20 w-20 items-center justify-center rounded-full bg-[#FFECE9]">
          <PhosphorIcon
            name="sign-out"
            fontSize={48}
            class="text-errorDark"
            size="light"
          />
        </div>
        <p class="w-4/5 text-center text-h3 text-textDark">
          Are you sure you want to log out?{" "}
        </p>
      </div>
      <div class="border-t border-baseTertiaryDark p-4">
        <div class="flex gap-3">
          <button
            class="!h-10 w-full rounded-lg border border-basePrimaryDark !py-2.5 px-4 !text-subtitleSmall text-textDark"
            onClick={closeModal}
          >
            Cancel
          </button>
          <ButtonRegular
            class="!h-10 w-full rounded-lg bg-errorDark !py-2.5 px-4 !text-subtitleSmall text-white"
            onClick={logoutHandler}
            isLoading={showLogoutLoader()}
            isRectangular={true}
            isEnabled={true}
          >
            Yes, log out
          </ButtonRegular>
        </div>
      </div>
    </div>
  );
};
